import React from 'react';
import useUser from '@hooks/useUser';
import { SplashScreen, TrendingPosts } from '@components';
import { App } from '@layouts';

const Index = () => {
  const { loading } = useUser();

  if (loading) {
    return <SplashScreen />;
  }
  return null;
  return (
    <App title="Trending">
      <TrendingPosts />
    </App>
  );
};

export default Index;
